import { PROJECT_TARIFFS } from '@/Constants/Tariffs';

export const calculateProjectCost = (totalProjects: number) => {
  let remainingProjects = totalProjects;
  let totalCost = 0;

  for (const tariff of PROJECT_TARIFFS) {
    const projectRangeCount = Math.min(
      tariff.project_count_max
        ? tariff.project_count_max - tariff.project_count_min
        : remainingProjects,
      remainingProjects,
    );

    if (tariff.price_for_five > 0) {
      const groupsOfFive = Math.ceil(projectRangeCount / 5);
      totalCost += groupsOfFive * tariff.price_for_five;
    }

    remainingProjects -= projectRangeCount;

    if (remainingProjects <= 0) break;
  }

  return totalCost;
};
