import useApi from '@/api/useApi';
import { isValidDateString } from '@/pages/report/utils/date-helpers';
import { useLazyGetBmAccessTokenQuery, useLazyGetMoscowDateTimeQuery } from '@/redux/api/other-api';
import { setDate } from '@/redux/slices/comparisons-data-slice';
import { type RootState } from '@/redux/store';
import MarplaHeader, { getMarplaExtensionNavLink } from '@marpla/ui/components/Header';
import { type BalanceProps } from '@marpla/ui/components/Header/Balance';
import IconButton from '@marpla/ui/components/IconButton';
import { enqueueSnackbar } from '@marpla/ui/components/SnackbarProvider';
import LogOutIcon from '@marpla/ui/icons/LogOut';
import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import navLinks from './navlinks';

function UserMenuContent() {
  const user = useSelector((state: RootState) => state.user);

  const { resetToken } = useApi();
  const onLogOut = useCallback(() => {
    if (window.confirm('Выйти из аккаунта?')) {
      resetToken();
      window.location.href = '/signin';
    }
  }, [resetToken]);

  const onCopyEmail = useCallback(() => {
    navigator.clipboard.writeText(String(user.email)).then(
      () => {
        enqueueSnackbar('Email скопирован', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      },
      () => {
        enqueueSnackbar('Копирование из браузера не поддерживается системой', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      },
    );
  }, [user.email]);

  return (
    <div className={classnames(styles.navLink, styles.disableHover)}>
      <span onClick={onCopyEmail} className={styles.email}>
        {user.email || 'Аккаунт'}
      </span>
      <IconButton variant="secondary" size="md" sx={{ ml: 1 }} onClick={onLogOut}>
        <LogOutIcon />
      </IconButton>
    </div>
  );
}

export default function Header() {
  const dispatch = useDispatch();
  const [triggerGetMoscowDateTimeMutation] = useLazyGetMoscowDateTimeQuery();
  const user = useSelector((state: RootState) => state.user);
  const [triggerGetBmAccessToken] = useLazyGetBmAccessTokenQuery();
  const { date } = useSelector((state: RootState) => state.comparisonsTabData);

	const [bmToken, setBmToken] = useState<string | null>(null)

	const marplaExtensionNavLink = useMemo(
		() => getMarplaExtensionNavLink(bmToken),
		[bmToken]
	)

	const navLinksWithMarplaExtension = useMemo(
		() => [
			...navLinks.slice(0, 3),
			marplaExtensionNavLink,
			...navLinks.slice(3),
		],
		[marplaExtensionNavLink]
  )

  const getMoscowDate = useCallback(async () => {
    const response = await triggerGetMoscowDateTimeMutation({});

    if ('error' in response) {
      return new Date();
    }

    if (isValidDateString(response.data.datetime)) {
      const serverDate = new Date(response.data.datetime);

      if (!date) {
        dispatch(setDate(response.data?.datetime?.slice(0, 10)));
      }

      return serverDate;
    }

    return new Date();
  }, [date, dispatch, triggerGetMoscowDateTimeMutation]);

  useEffect(() => {
    triggerGetBmAccessToken({env: 'master'}).then((response) => {
      if ('data' in response) {
        setBmToken(response.data.accessToken);
        localStorage.setItem('bmT', response.data.accessToken)
      }
    });
  }, [triggerGetBmAccessToken]);

  const balanceProps: BalanceProps = {
    bonusTime: user.bonusTime.hasTime ? user.bonusTime : undefined,
    linkTo: '/finance',
    balance: user.balance,
  };

  return (
    <MarplaHeader
      navLinks={navLinksWithMarplaExtension}
      userMenuContent={<UserMenuContent />}
      getMoscowDate={getMoscowDate}
      balanceProps={user.isLoadingFinanceData ? undefined : balanceProps}
    />
  );
}
