import { type TopLevelTabs } from '@/pages/report/App';
import { type PhraseRow, type SeoList } from '@/pages/report/utils/report-table-data-processor';
import { type ISODateString } from '@/types/types';
import { createSlice } from '@reduxjs/toolkit';

import { type RootState } from '../store';

export type SeoListExtended = SeoList & {
  foundPhrasesCount: number;
  totalPhrasesCount: number;
};

export interface ProjectPhrasesData {
  checkedPhraseIds: PhraseRow['id'][];
  // checkedPhraseRows: PhraseRow[]
  listId: SeoList['id'];
  mainRadioFilter: string;
  topRadioFilter: string;
  isBoostModeActive: boolean;
  hideExtraColumns: boolean;
  lists: SeoListExtended[];
  productCardChangeDays: ISODateString[];
  extraPositions: boolean;
}

const initialState: ProjectPhrasesData = {
  checkedPhraseIds: [],
  // checkedPhraseRows: [],
  listId: 'all',
  mainRadioFilter: 'all',
  topRadioFilter: 'all',
  isBoostModeActive: false,
  lists: [],
  productCardChangeDays: [],
  hideExtraColumns: false,
  extraPositions: false,
};

export interface Action<P> {
  type: string;
  payload: P;
}

export const projectPhrasesDataSlice = createSlice({
  name: 'projectPhrasesDataSlice',
  initialState,
  reducers: {
    setCheckedPhraseIds: (state, action: Action<string[]>) => ({
      ...state,
      checkedPhraseIds: action.payload,
    }),
    setListId: (state, action: Action<string>) => ({
      ...state,
      listId: action.payload,
    }),
    setMainRadioFilter: (state, action: Action<string>) => ({
      ...state,
      mainRadioFilter: action.payload,
    }),
    setTopRadioFilter: (state, action: Action<string>) => ({
      ...state,
      topRadioFilter: action.payload,
    }),
    setLists: (state, action: Action<SeoListExtended[]>) => ({
      ...state,
      lists: action.payload,
    }),
    addList: (state, action: Action<SeoListExtended>) => ({
      ...state,
      lists: state.lists.concat(action.payload),
    }),
    setProductCardChangeDays: (state, action: Action<ISODateString[]>) => ({
      ...state,
      productCardChangeDays: action.payload,
    }),
    setIsBoostModeActive: (state, action: Action<boolean>) => ({
      ...state,
      isBoostModeActive: action.payload,
    }),
    setHideExtraColumns: (state, action: Action<boolean>) => ({
      ...state,
      hideExtraColumns: action.payload,
    }),
    setExtraPositions: (state) => ({
      ...state,
      extraPositions: !state.extraPositions,
    }),
    reset: () => ({ ...initialState }),
  },
});

export const {
  setCheckedPhraseIds,
  setListId,
  setMainRadioFilter,
  setTopRadioFilter,
  setLists,
  addList,
  setProductCardChangeDays,
  setIsBoostModeActive,
  setHideExtraColumns,
  setExtraPositions,
  reset,
} = projectPhrasesDataSlice.actions;

export default projectPhrasesDataSlice.reducer;

export const getCurrentListName = (tab: TopLevelTabs) => (state: RootState): string => {
  if (tab === 'project_phrases' || tab === 'chat_gpt') {
    return state.projectPhrasesData?.lists?.find((item) => item.id === state.projectPhrasesData?.listId)?.name || '';
  }

  if (tab === 'comparsions') {
    return state.comparisonsTabData?.lists?.find((item) => item.id === state.comparisonsTabData?.listId)?.name || '';
  }

  if (tab === 'phrases_library') {
    return state.phrasesLibraryData?.lists?.find((item) => item.id === state.phrasesLibraryData?.listId)?.name || '';
  }

  return '';
};
