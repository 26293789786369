import { CURRENCY_TOKENS, FORMATTER_LANG, NO_RESULT } from '@/Constants';
import { type GridSortCellParams, type GridSortItem } from '@mui/x-data-grid-premium';
import { format, parse, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import escapeStringRegexp from 'escape-string-regexp';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

type PluralizeFunc = (n: number, singular: string, plural: string, accusative: string) => string;

export const pluralize: PluralizeFunc = (n, singular, plural, accusative) => {
  n = Math.abs(Number(n));

  const n10 = n % 10;
  const n100 = n % 100;

  if (n10 === 1 && n100 !== 11) {
    return singular;
  }

  if (n10 >= 2 && n10 <= 4 && !(n100 >= 12 && n100 <= 14)) {
    return plural;
  }

  return accusative;
};

export const divideNumber = (n: number): string => String(n).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const wait = (delay: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, delay));

type DateFormatter = (date: Date) => string;

export const dateFormatter: DateFormatter = (date) => format(date, 'dd.MM.yyyy');

export const dateHoursFormatter: DateFormatter = (date) => format(date, 'dd.MM.yyyy HH:mm');

export const isNumeric = ((x: any) => isFinite(x) && x !== null) as (
  x: any,
) => x is number | `${number}`;

type ObjectCountKeysFunc = (obj: object) => number;

export const objectCountKeys: ObjectCountKeysFunc = (obj) => Object.keys(obj).length;

type FilterObjectFunc = <T>(
  obj: Record<PropertyKey, T>,
  f: (key: string, value?: T, index?: number) => boolean,
) => Record<string, Partial<T>>;

export const filterObject: FilterObjectFunc = (obj, f) =>
  Object.fromEntries(Object.entries(obj).filter((pair, index) => f(pair[0], pair[1], index)));

type MapObjectFunc = <T, U>(
  obj: Record<PropertyKey, T>,
  f: (key: string, value?: T, index?: number) => [PropertyKey, U],
) => Record<PropertyKey, U>;

export const mapObject: MapObjectFunc = (obj, f) =>
  Object.fromEntries(Object.entries(obj).map((item, index) => f(item[0], item[1], index)));

type ArrayEndFunc = <T>(arr: T[]) => null | T;

export const arrayEnd: ArrayEndFunc = (arr) => (arr.length === 0 ? null : arr[arr.length - 1]);

type StringEndFunc = (str: string) => null | string;

export const stringEnd: StringEndFunc = (str) => (str.length === 0 ? null : str[str.length - 1]);

export const removeSpaces = (str: string): string => str.replace(/\s/g, '');

interface ArticleData {
  article: string;
  note: string | null;
}

type TransformArticlesFunc = (articles: string[]) => ArticleData[];

export const trim = (str: string | number): string =>
  String(str)
    .trim()
    .replace(/[\n ]+/g, ' ');

export const transformArticles: TransformArticlesFunc = (articles) =>
  articles
    .map(trim)
    .filter((item) => /^\d{5,}(.+)?$/.test(item))
    .map((item) => {
      const entities = /^(\d{5,})(.+)?$/.exec(item)!;
      const article: string = entities[1];
      let note: string | null = null;

      if (entities[2]) {
        note = trim(entities[2]).replace(/^[-–—]+ ?/, '') || null;
      }

      return { article, note };
    })
    .filter((item, index, array) => array.findIndex((j) => j.article === item.article) === index);

export const getWBProductLink = (article: string): string =>
  `https://www.wildberries.ru/catalog/${article}/detail.aspx`;

export const getWBBrandLink = (brandUrl: string): string => `https://www.wildberries.ru${brandUrl}`;

type FilterThenMapFunc = <T, U>(
  arr: T[],
  filterFunction: (item: T) => boolean,
  mapFunction: (item: T) => U,
  debug?: boolean,
) => U[];

export const filterThenMap: FilterThenMapFunc = (arr, filterFunction, mapFunction, debug) => {
  const result: any[] = [];

  for (const item of arr) {
    if (!filterFunction(item)) {
      debug && console.warn(`Filtered item:`, { array: arr, item });

      continue;
    }

    result.push(mapFunction(item));
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return result;
};

export const getCookie = (key: string): string | undefined | { [key: string]: string } => {
  if (document.cookie === '') {
    return undefined;
  }

  const cookie = document.cookie
    .split(';')
    .map((v) => v.split('='))
    .reduce<Record<string, string>>((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());

      return acc;
    }, {});

  if (key) {
    return cookie[key];
  }

  return cookie;
};

export const addCookie = (
  key: string, // Имя куки
  value: string, // значение
  days?: number, // кол-во дней жизни куки
  path: string = '/', // путь для которого куки будут доступны
): void => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
    value,
  )}${expires}; path=${path}`;
};

export const isEmpty = (x: any): x is null | undefined | '' => isNil(x) || x === '';

// todo: удалить если не успользуется
export const sortComparator = (
  a: any,
  b: any,
  rowA: GridSortCellParams,
  // rowB: GridSortCellParams
): number => {
  const direction =
    rowA.api.getSortModel().find((item: GridSortItem) => item.field === rowA.field)?.sort || 'desc';

  if (isString(a) || isString(b)) {
    return String(b).localeCompare(String(a));
  }

  if (isEmpty(a) && isEmpty(b)) {
    return 0;
  }

  if (isEmpty(a)) {
    return direction === 'asc' ? 1 : -1;
  }

  if (isEmpty(b)) {
    return direction === 'asc' ? -1 : 1;
  }

  return a - b;
};

export const disableDataGridLicenseError = (): void => {
  const originConsoleError = window.console.error;

  window.console.error = (...args: unknown[]) => {
    if (args.length <= 2 && isString(args[0]) && args[0].includes('MUI: Missing license key')) {
      return;
    }

    originConsoleError(...args);
  };
};

export const firstUppserCase = (str: string): string => {
  if (str === '') {
    return '';
  }

  if (str.length === 1) {
    return str.toUpperCase();
  }

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const sortComparatorForPositions = (
  valueA: any,
  valueB: any,
  rowA: GridSortCellParams,
  rowB: GridSortCellParams,
  ISODateString: string,
): number => {
  const direction = rowA.api
    .getSortModel()
    .find((item: GridSortItem) => item.field === rowA.field).sort;
  const newRowA = rowA.api.getRow(rowA.id).positionsByDate[ISODateString];
  const newRowB = rowB.api.getRow(rowB.id).positionsByDate[ISODateString];

  const newValueA = isNumber(valueA) ? valueA * 100000 + (newRowA.beforePromotion || 0) : null;

  const newValueB = isNumber(valueB) ? valueB * 100000 + (newRowB.beforePromotion || 0) : null;

  if (newValueA === newValueB) {
    return 0;
  }

  if (newValueA === null) {
    return direction === 'asc' ? 1 : -1;
  }

  if (newValueB === null) {
    return direction === 'asc' ? -1 : 1;
  }

  // Оба значения не равны null, продолжаем сравнивать их
  if (newValueA !== newValueB) {
    return newValueA - newValueB;
  }

  if (newRowA.hasPromotion && !newRowB.hasPromotion) {
    return 1;
  }

  if (!newRowA.hasPromotion && newRowB.hasPromotion) {
    return -1;
  }

  return 0;
};

export const objectToUrlParams = (obj: Record<string, any>): string => {
  const usp = new URLSearchParams();

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      usp.append(key, obj[key]);
    }
  }

  return usp.toString();
};

interface Chunk {
  start: number;
  end: number;
}

type FindChunksFunc = (params: { searchWords: string[]; textToHighlight: string }) => Chunk[];

export const findChunks: FindChunksFunc = ({ searchWords, textToHighlight }) => {
  const result: Chunk[] = [];
  const textToHighlightTransformed = textToHighlight
    .replace(/[ёË]/g, 'e')
    .replace(/[^A-Za-zА-Яа-яёË0-9 ]/gm, ' ');
  searchWords
    .filter((w) => w !== '')
    .forEach((word) => {
      const escapedWord = escapeStringRegexp(word.replace(/[ёË]/g, 'e'));
      const matchedWords = textToHighlightTransformed.matchAll(
        new RegExp(`^${escapedWord}$|^${escapedWord} | ${escapedWord}$| ${escapedWord} `, 'ig'),
      );

      for (const matchedWord of matchedWords) {
        result.push({
          start: matchedWord.index,
          end: matchedWord.index + matchedWord[0].length,
        });
      }
    });

  return result;
};

export const getProductCharacteristics = (
  characteristics: Array<{
    name: string;
    value: string;
  }>,
) => {
  if (!Array.isArray(characteristics) || characteristics.length === 0) {
    return [];
  }
  const _characteristics = characteristics.filter(
    (item) => !['Название', 'Описание'].includes(item.name),
  );
  if (_characteristics[0].name === 'Комплектация') {
    return _characteristics.slice(1);
  }
  return _characteristics;
};

export const startsFromNumber = (str: string): boolean => /^[0-9]/.test(str);

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getTimeDifference = (date: string) => {
  const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  const now = new Date();

  const days = differenceInDays(parsedDate, now);
  const hours = differenceInHours(parsedDate, now) % 24;
  const minutes = differenceInMinutes(parsedDate, now) % 60;

  return `${days} ${pluralize(days, 'день', 'дня', 'дней')} 
       ${hours} ${pluralize(hours, 'час', 'часа', 'часов')} 
       ${minutes} ${pluralize(minutes, 'минута', 'минуты', 'минут')}`;
};

export const truncateString = (str: string, maxLength: number = 30): string => {
  if (str.length <= maxLength) {
    return str;
  }

  const truncated = str.slice(0, maxLength).replace(/\S*$/, '');

  return `${truncated}...`;
};

const NUMBER_FORMATTERS = new Map<number, Intl.NumberFormat>();

export function formatNumber(value: unknown, digits: number = 0): string {
  if (!NUMBER_FORMATTERS.has(digits)) {
    NUMBER_FORMATTERS.set(
      digits,
      new Intl.NumberFormat(FORMATTER_LANG, { maximumFractionDigits: digits }),
    );
  }

  return isNumber(value) ? NUMBER_FORMATTERS.get(digits)!.format(value) : NO_RESULT;
}

const CURRENCY_FORMATTER = new Intl.NumberFormat(FORMATTER_LANG, { maximumFractionDigits: 0 });

export function formatCurrency(value: unknown, options: FormatCurrencyOptions = {}): string {
  const { currency = 'RUB' } = options;
  const sign = CURRENCY_TOKENS[currency as keyof typeof CURRENCY_TOKENS] || currency;

  value = isNumber(value) ? CURRENCY_FORMATTER.format(value) : NO_RESULT;

  return `${value} ${sign}`;
}

export interface FormatCurrencyOptions {
  currency?: string;
}
export const searchEveryWord =
  (search: string, name: string, isMinus: boolean): boolean => search.split(' ').every(
    (word) => isMinus
      ? !name.toLowerCase().includes(word)
      : name.toLowerCase().includes(word),
  );
